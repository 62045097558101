var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('md-card',[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Добавить витрину")])])]),_c('ValidationObserver',{ref:"addStorefrontForm",attrs:{"slim":true}},[_c('md-card-content',[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Название")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.storefront.title),callback:function ($$v) {_vm.$set(_vm.storefront, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"storefront.title"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Иконка")]),_c('md-file',{ref:"imageLoader",attrs:{"accept":"image/*"},on:{"md-change":function($event){_vm.storefront.cover = $event[0];
                  reset();}},model:{value:(_vm.iconName),callback:function ($$v) {_vm.iconName=$$v},expression:"iconName"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Ключ")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.storefront.key),callback:function ($$v) {_vm.$set(_vm.storefront, "key", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"storefront.key"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Время жизни товаров (в днях)")]),_c('md-input',{attrs:{"type":"number","min":"1"},on:{"focus":reset,"change":_vm.validateLifetime},model:{value:(_vm.storefront.lifetime),callback:function ($$v) {_vm.$set(_vm.storefront, "lifetime", $$v)},expression:"storefront.lifetime"}})],1)]}}])}),_c('div',{staticClass:"buttons-wrap"},[_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.addNewStorefront}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }