<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Добавить витрину</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="addStorefrontForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model.trim="storefront.title" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Иконка</label>
                <md-file
                  ref="imageLoader"
                  v-model="iconName"
                  accept="image/*"
                  @md-change="
                    storefront.cover = $event[0];
                    reset();
                  "
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Ключ</label>
                <md-input v-model.trim="storefront.key" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Время жизни товаров (в днях)</label>
                <md-input
                  v-model="storefront.lifetime"
                  type="number"
                  min="1"
                  @focus="reset"
                  @change="validateLifetime"
                />
              </md-field>
            </ValidationProvider>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="addNewStorefront">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

const initStorefront = () => ({
  title: null,
  cover: null,
  key: null,
  lifetime: null,
});

export default {
  data() {
    return {
      iconName: "", // hack for vee-validate
      storefront: initStorefront(),
    };
  },

  methods: {
    ...mapActions("storefronts", ["createStorefront"]),

    async addNewStorefront() {
      const isValid = await this.$refs.addStorefrontForm.validate();
      if (!isValid) {
        return;
      }

      let storefront = new FormData();

      storefront.append("title", this.storefront.title);
      storefront.append("cover", this.storefront.cover);
      storefront.append("key", this.storefront.key);
      storefront.append("parameters[][key]", "products_lifetime");
      storefront.append("parameters[][name]", "Время жизни товаров (в днях)");
      storefront.append("parameters[][value]", this.storefront.lifetime);

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.createStorefront(storefront);
      if (success) {
        this.initStorefront();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    initStorefront() {
      this.$refs.imageLoader.clearField();
      this.storefront = initStorefront();
    },

    validateLifetime(event) {
      if (event.target.value < 1) {
        this.storefront.lifetime = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
